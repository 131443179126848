export default {
  SET_ACTIVE_ENTITY(state, payload) {
    state.activeEntity = payload
  },
  SET_ENTITY_PROVIDED_SERVICE_LIST(state, payload) {
    state.entityProvidedServiceList = payload
  },
  SET_ENTITY_PAYMENT_SERVICE_LIST(state, payload) {
    state.entityPaymentServiceList = payload
  },
  SET_ENTITY_PAYMENT_METHOD_LIST(state, payload) {
    state.entityPaymentMethodList = payload
  }
}